.mouse {
    display: block;
    width: 28.92px;
    height: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: fixed;
    bottom: 10%;
    left: calc(50% - 10.56px);
}

@media screen and (max-width: 1140px) {
    .mouse {
        display: none;
    }
}