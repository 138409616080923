@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@700&display=swap");
@import "../../node_modules/animate.css/animate.min.css";
@import "variables";
@import "libs/normalize";

@import "components/all";

body {
  font-family: "Open Sans", sans-serif;
  background-color: #03030e;
  font-size: 16px;
}

.container-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding: 50px 0;
  position: relative;

  &--main {
    background-image: url("../assets/img/main-bg.jpg");
  }

  &--footer {
    background-image: url("../assets/img/footer-bg.jpg");
  }
}

.wrapper {
  max-width: 1470px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;

  &--small {
    max-width: 620px;
  }
}

.mt50 {
  margin-top: 50px;
}

.title {
  font-weight: 300;
  font-size: 5em;
  line-height: 1.6;
  color: #ffffff;

  text-shadow: 0px 0px 10px rgba(61, 222, 237, 0.5);

  span {
    font-weight: normal;
    color: #3ddeed;
  }
}

.subtitle {
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.3;
  color: #3ddeed;
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;

  li {
    padding-left: 12px;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    position: relative;
    margin: 0 10px;
    color: $white;

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 14px;
      left: 0;
      background-color: $white;
    }
  }

  &__item {
    border: 2px solid $white;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    line-height: 25px;
    color: $white;
    margin: 10px;
  }
}

p {
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;

  color: $white;
}

.link {
  text-decoration: underline;
}

.simplebar-scrollbar::before {
  background-color: transparent;
}

.donate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 52px;
  padding-bottom: 100px;

  h2.subtitle {
    margin-top: 0;
    margin-right: 30px;

    & + div {
      margin-right: 60px;
    }
  }

  .buy-me-a-coffee {
    width: 11rem;
    height: 34px;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1140px) {
  body {
    font-size: 12px;
  }

  .container-bg {
    padding: 15px 0 100px;
  }

  .row {
    flex-direction: column;
    align-items: center;
    max-width: 920px;
    margin: 0 auto;
    padding: 30px 10px 0;

    &--mobile-reverse {
      flex-direction: column-reverse;
    }

    &--line {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .mt50.col {
    margin-top: 0;
  }
}

@media screen and (max-width: 960px) {
  .donate {
    flex-direction: column;
    align-items: flex-start;

    h2.subtitle {
      margin-bottom: 10px;

      & + div {
        margin-bottom: 15px;
      }
    }
  }

  .project {
    padding-bottom: 20%;
  }
}

@media screen and (max-width: 749px) {
  body {
    font-size: 8px;
  }

  p {
    font-size: 18px;
    line-height: 1.4;
  }

  .subtitle {
    font-size: 2em;
  }
}

@media screen and (max-width: 547px) {
  .row--line {
    flex-direction: column;
    align-items: flex-start;
  }
}
