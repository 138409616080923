.avatar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-width: 579px;
    height: 490px;
    border-radius: 36px;
    background: linear-gradient(
      90deg,
      #3ddeed 0%,
      #cb00dd 99.66%,
      #cb00dd 100%
    );
    border: 1px solid transparent;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 36px;
    }
  }

  &-project {
    height: auto;

    img {
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1140px) {
  .avatar {
    margin-bottom: 50px;
    justify-content: center;
  }
}

@media screen and (max-width: 749px) {
  .avatar {
    img {
      height: 450px;
    }

    &-project {
      height: auto;

      img {
        height: auto;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 549px) {
  .avatar {
    &-wrapper {
      height: 300px;

      img {
        height: 300px;
      }
    }
  }
}
