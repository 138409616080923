@-webkit-keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.modal.active {
  display: flex;
}

.modal__content {
  margin: auto;
  display: block;
  width: 80%;
  height: 80vh;
  object-fit: contain;
  margin-bottom: 30px;
}

/* Add Animation */
.modal__content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

/* The Close Button */
.modal__close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}

.modal__close:hover,
.modal__close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Caption of Modal Image */
.modal__caption {
  display: flex;
  justify-content: center;
  text-align: center;
  color: $white;
  margin-top: 30px;
  margin-left: auto;
  width: 100%;
  max-width: 579px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 60px;
    border-radius: 8px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='250' height='60' viewBox='0 0 250 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect x='1' y='1' width='248' height='58' rx='7' stroke='%23F1F1F1' stroke-width='2'/%3E %3C/svg%3E ");

    font-size: 24px;
    line-height: 33px;
    color: $white;
  }

  a:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='250' height='60' viewBox='0 0 250 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect x='1' y='1' width='248' height='58' rx='7' stroke='url(%23paint0_linear)' stroke-width='2'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear' x1='9.31323e-07' y1='30' x2='58.5176' y2='151.912' gradientUnits='userSpaceOnUse'%3E %3Cstop stop-color='%233DDEED'/%3E %3Cstop offset='1' stop-color='%23CB00DD'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E ");
  }
}

@media only screen and (max-width: 1140px) {
  .modal__caption {
    max-width: 100%;
    margin-top: -25px;
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal__content {
    width: 90%;
  }
}
