.cloud {
    width: 750px;
    height: 649px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-image: url("../../assets/icons/kvadrat.svg");
    margin-top: -50px;
    margin-bottom: -50px;
    margin-right: -100px;

    position: relative;


    img {
        display: none;
        width: 100%;
        object-fit: contain;
    }


    span {
        font-family: 'Nunito', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: $white;

        text-shadow: 0px 0px 10px rgba(61, 222, 237, 0.5);

        position: absolute;

        &:nth-child(1) {
            top: 160px;
            left: 440px;
        }

        &:nth-child(2) {
            top: 200px;
            left: 266px;
        }

        &:nth-child(3) {
            top: 265px;
            left: 350px;
        }


        &:nth-child(3) {
            top: 265px;
            left: 320px;
        }

        &:nth-child(4) {
            top: 320px;
            left: 470px;
        }

        &:nth-child(5) {
            top: 350px;
            left: 220px;
        }

        &:nth-child(6) {
            top: 400px;
            left: 390px;
        }

        &:nth-child(7) {
            top: 455px;
            left: 250px;
        }
    }
}

@media screen and (max-width: 1520px) {
    .cloud {
        margin-right: 0;
    }
}

@media screen and (max-width: 1140px) {
    .cloud {
        width: 100%;
        max-width: 750px;
        min-height: 325px;
        height: auto;
        background: none;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;

        position: relative;

        img {
            display: block;
        }

        span {
            display: none;
        }
    }
}