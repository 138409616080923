.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 100px);

  img {
    width: 100%;
    max-width: 678.99px;
    object-fit: contain;
  }
}

.go-home {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
  line-height: 30px;
  color: $white;

  &::before {
    content: url("../../assets/icons/back.svg");
    margin-right: 10px;
    height: 30px;
  }

  &:hover {
    background: #3ddeed;
    background: -webkit-linear-gradient(
      to bottom right,
      #3ddeed 30%,
      #cb00dd 100%
    );
    background: -moz-linear-gradient(
      to bottom right,
      #3ddeed 30%,
      #cb00dd 100%
    );
    background: linear-gradient(to bottom right, #3ddeed 30%, #cb00dd 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
