.slider {
  width: 100%;
  display: flex;
  min-width: 650px;
}

.slide {
  height: 310px;
  border-radius: 20px;
  margin: 10px;
  cursor: zoom-in;
  color: #000;
  font-weight: bold;
  flex: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transition: all 500ms ease-in-out;

  &.active {
    cursor: pointer;
  }
}

@media screen and (max-width: 1140px) {
  .slide {
    height: 210px;

    &.active {
      flex: 5 !important;
    }
  }
}
