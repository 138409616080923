.socials {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, 60px);
    grid-gap: 25px;
    margin-top: auto;
}

.socials__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect x='1' y='1' width='58' height='58' rx='7' stroke='%23F1F1F1' stroke-width='2'/%3E %3C/svg%3E ");
}

.socials__item:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect x='1' y='1' width='58' height='58' rx='7' stroke='url(%23paint0_linear)' stroke-width='2'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear' x1='2.23517e-07' y1='30' x2='60' y2='60' gradientUnits='userSpaceOnUse'%3E %3Cstop stop-color='%233DDEED'/%3E %3Cstop offset='1' stop-color='%23CB00DD'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E ");
}